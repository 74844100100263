@import url('https://fonts.googleapis.com/css?family=Inter:400,600&display=swap');
@import 'partials/grid';
@import 'partials/header';
@import 'partials/article';
@import 'partials/author';

body {
	margin: 0;
	font-family: Inter, sans-serif;
	color: #225;
}


.hd-featured-primary {
	.hd-article {
		border-left: 1px solid #eee;
		border-right: 1px solid #eee;
	}
}
.hd-featured-secondary {
	.hd-article {
		border-bottom: 1px solid #eee;
	}
}
.hd-featured-popular {
	.hd-article {
		border-bottom: 1px solid #eee;
	}
}

.hd-article:last-child {
	border-bottom: none;
}

.hd-article-superline {
	display: inline-block;
	margin: .5rem 0 .25rem;
	font-size: .85rem;
	letter-spacing: 0;
	font-weight: 400;
	opacity: .8;
}

.hd-featured {
	.hd-featured-primary {
		text-align: center;
		.hd-article-superline {
			margin-top: 1.5rem;
		}
		h2 {
			font-size: 2rem;
		}
	}
	.hd-featured-popular {
		.hd-article-superline {
			margin-top: 0;
		}
		h2 {
			font-size: 1.15rem;
		}
	}
}