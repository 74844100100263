.author {
    display: flex;
    align-items: center;
    margin: 0 auto 3rem;
    justify-content: center;
    .author-circle {
        display: block;
        width: 72px;
        height: 72px;
        border-radius: 100%;
        padding: 4px;
        margin-right: 1rem;
        margin-left: 1rem;
        background: linear-gradient(to left, rgba(80,0,74,0) 30%, rgba(80,0,74,1)), linear-gradient(to top, #2DEEA8, #ED0260);
        .author-padding {
            background-color: #fff;
            padding: 4px;
            border-radius: 100%;
            img {
                width: 64px;
                height: 64px;
                min-width: 0;
                max-width: 64px;
                margin: 0;
                display: block;
                border-radius: 100%;
            }
        }
    }
    .author-text {
        width: 50%;
        max-width: 20rem;
        min-width: 200px;
    }
}