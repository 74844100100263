.hd-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: calc(5vw + 1rem);
	margin-right: calc(5vw + 1rem);
	margin-bottom: -3vw;
	border-bottom: 1px solid #ccc;
	padding: 1vw 0;
	a {
		transition: transform .1s ease-in-out;
		&:hover {
			transform: scale(1.05);
		}
	}
	.hd-logo {
		img {
			height: 10vw;
			max-height: 90px;
			min-height: 50px;
		}
	}
	.hd-nav {
		a {
			text-decoration: none;
			padding: 1rem;
			color: inherit;
		}
	}
}