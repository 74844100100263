a.hd-article {
	display: block;
	text-decoration: inherit;
	color: inherit;
	&:hover {
	}
	margin: 1rem;
	box-sizing: border-box;
	.hd-article-preview {
		width: 100%;
		background-color: #333;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.hd-article-superline {
	}
	h2 {
		margin: 0;
		padding: 0;
		line-height: 1.2em;
		font-weight: 400;
	}
}

.hd-featured {
	margin: 5vw;
	display: flex;
	flex-direction: row;
	.hd-featured-top3 {
		display: flex;
		flex-direction: row-reverse;
		flex-grow: 3;
		width: 75%;
		.hd-featured-primary {
			flex-grow: 2;
			width: 66%;
			.hd-article {
				margin-left: 0;
				margin-right: 0;
				padding-left: 1rem;
				padding-right: 1rem;
			}
			.hd-article-preview {
				height: 25vw;
			}
		}
		.hd-featured-secondary {
			flex-grow: 1;
			width: 33%;
			.hd-article {
				padding-bottom: 1rem;
			}
			.hd-article-preview {
				height: 10vw;
			}
		}
	}
	.hd-featured-popular {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		width: 25%;
		.hd-article {
			display: flex;
			flex-direction: row;
			padding-bottom: 1rem;
			margin-bottom: 0;
			.hd-article-preview {
				min-width: 8vw;
				max-width: 8vw;
				height: 5vw;
				margin-right: 1rem;
			}
		}
	}
}

.hd-categories {
	display: flex;
	margin: 5vw;
	.hd-category {
		width: 25%;
		.hd-article-preview {
			height: 13vw;
		}
	}
	.hd-category-big {
		width: 50%;
		display: flex;
		flex-wrap: wrap;
		.hd-article {
			width: 50%;
		}
	}
	.hd-category-full {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		.hd-article {
			width: 25%;
			margin: 0;
			padding: 1rem;
		}
	}
}

.hd-freebies {
	background-color: #F9E246; 
	margin: 2vw 0;
	padding: 4vw 0 1vw;
}

.hd-videos {
	background: #111;
	color: #ddd;
	padding: 2vw 0;
	margin-bottom: 3vw;
	.hd-categories {
		margin-bottom: 0;
	}
}

.hd-newsletter {
	min-height: 30vw;
	background-color: #F9E246;
	background-image: url(/assets/email-bg.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-radius: 8px;
	font-size: 1.5rem;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5vw;
	flex-direction: column;
	padding: 1rem;
	box-sizing: border-box;
	&.hd-experts {
		background-image: url(/assets/human-deluxe-experts.jpg);
		background-size: cover;
		background-position: center right;
		background-repeat: no-repeat;
		padding: 5vw;
		text-align: left;
		align-items: flex-start;
		h2 {
			width: 40%;
		}
		a {
			color: #FC1581;
			text-decoration: none;
		}
	}
}

@media (max-width: 900px) {
	.hd-featured {
		flex-direction: column;
		.hd-featured-top3 {
			width: 100%;
			flex-direction: row;
		}
		.hd-featured-popular {
			width: 100%;
			flex-direction: row;
			flex-wrap: wrap;
			border-top: 1px solid #eee;
			.hd-article {
				width: calc(50% - 2rem);
				box-sizing: border-box;
			}
		}
	}
	.hd-categories {
		.hd-category-full {
			.hd-article {
				width: 50%;
				.hd-article-preview {
					height: 26vw;
				}
				h2 {
					font-size: 1.1rem;
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.hd-featured {
		margin: 0vw;
		flex-direction: column;
		.hd-featured-top3 {
			width: 100%;
			flex-direction: column;
			.hd-featured-primary,
			.hd-featured-secondary,
			.hd-article {
				border: none!important;
				width: auto;
				.hd-article-preview {
					height: 50vw;
				}
			}
		}
		.hd-featured-popular {
			width: 100%;
			flex-direction: column;
			flex-wrap: wrap;
			border-top: 1px solid #eee;
			.hd-article {
				width: calc(100% - 2rem);
				box-sizing: border-box;
				.hd-article-preview {
					min-width: 24vw;
    			max-width: 24vw;
    			height: 15vw;
				}
			}
		}
	}
	.hd-categories {
		margin: 2vw;
		.hd-category-full {
			.hd-article {
				width: 50%;
				padding: 1vw;
			}
		}
	}
}

.hd-more {
	display: block;
	text-align: center;
	padding: 2rem 0 5rem;
	a {
		display: inline-block;
		color: #222;
		border: 1px solid;
		padding: 1rem 2rem;
		text-decoration: none;
	}
}

.hd-subline {
	text-align: center;
	font-size: 14px;
	text-transform: uppercase;
}

.hd-category-divider {
	text-transform: uppercase;
	display: block;
	border-bottom: 1px solid;
	margin: 0 calc(5vw + 1rem) -4vw;
	@media(max-width: 500px) {
		margin: 0 3vw 2vw;
	}
}