.hd-long-article {
	min-width: 300px;
	width: 50%;
	max-width: 40rem;
	margin: 5vw auto;
	@media (max-width: 480px) {
		width: 90%;
	}
	h1 {
		text-align: left;
		padding-top: 3rem;
		font-size: 3rem;
		letter-spacing: -.1rem;
	}
	a {
		color: #0ca;
	}
	p, 
	li {
		font-family: Inter, sans-serif;
		font-size: 1rem;
		line-height: 150%;
		@media (max-width: 480px) {
			font-size: 1.2rem;
		}
	}
	img {
		min-width: calc(100% + 10vw);
		max-width: calc(100% + 10vw);
		margin-left: -5vw;
	}
	table {
		margin-bottom: 2rem;
		tr {
			td {
				border-top: 1px solid #ddd;
				padding: 0.5rem 2rem .5rem 0;
			}
		}
	}
}

.hd-video {
	background: radial-gradient(#333,#000);
	margin-top: 2rem;
	.hd-video-player {
		height: 56vw;
		max-height: 70vh;
		width: 100vw;
		max-width: 125vh;
		display: block;
		margin: 0 auto;
	}
}

.embed-youtube {
	border: none;
	width: 120%;
	margin-left: -10%;
}